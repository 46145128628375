import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071970"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div className="subPage" id="privacyPolicyPage">
              <div className="inner">
              <h1>Adatvédelmi irányelv</h1>
                <p>
                A JNTL Consumer Health (Hungary) Kft. számára fontos az Ön adatainak védelme, és szeretné, ha Ön megismerné, hogyan történik nálunk az információk gyűjtése, felhasználása és közzététele. Adatvédelmi irányelvünk bemutatja, hogy társaságunk vagy szolgáltatóink milyen módszereket alkalmaz(nak) az általunk működtetett és ellenőrzött, az Ön által megnyitott Adatvédelmi irányelvnek is helyet adó webhelyen vagy alkalmazáson (a továbbiakban „Szolgáltatás”). Személyes információk megadásával vagy a Szolgáltatás használatával elismeri, hogy elolvasta és megértette a jelen adatvédelmi irányelvet.
                </p>
                <h2>KISKORÚAK ÁLTALI HASZNÁLAT</h2>
                <p>
                A Szolgáltatás nem céloz meg 16 kor alatti személyeket, és kérjük, hogy ezek a személyek a Szolgáltatáson keresztül ne adjanak meg személyes adatokat. Amennyiben gyermeke személyes adatokat adott meg, és Ön szeretné, ha eltávolítanánk azokat, kérjük, vegye fel velünk a kapcsolatot az alábbi „Elérhetőségeink” című részben foglaltak szerint.
                </p>
                <h2>KISKORÚAK ÁLTALI HASZNÁLAT</h2>
                <p>
                A 16 életkor alatti személy a Szolgáltatást csak szülője vagy gyámja előzetes engedélyével veheti igénybe. A szülő vagy gyám engedélyének megszerzésére vonatkozó utasítások a Szolgáltatás felületén találhatók.
                </p>
                <h2>ADATGYŰJTÉS</h2>
                <p>
                Esetlegesen személyes adatok megadására kérhetjük annak érdekében, hogy&nbsp;meghatározott szolgáltatások (például hírlevelekre való feliratkozás, tippek/mutatók vagy rendelésfeldolgozás) előnyeit kihasználhassa, illetve hogy részt vehessen egy adott tevékenységben (például sorsolásos játék vagy más promóció). Tájékoztatni fogjuk arról, hogy milyen adatra van szükség, és milyen adatok opcionálisak.
                </p>
                <p>
                Az Ön által&nbsp;megadott adatot&nbsp;összekapcsolhatjuk más, Öntől online vagy offline módon&nbsp;gyűjtött adatokkal, beleértve például az Ön előző vásárlásait is. Összekapcsolhatjuk az adatokat Önre vonatkozó információval, amelyet más Kenvue leányvállalatoktól kapunk.
                </p>
                <p>
                Ha másra vonatkozó személyes adatokat bocsát a rendelkezésünkre, kijelenti, hogy jogában áll megadni a szóban forgó adatokat, továbbá engedélyezi a számunkra az adatok jelen adatvédelmi irányelvben foglaltak szerinti felhasználását.
                </p>
                <h2>ÉRZÉKENY INFORMÁCIÓ</h2>
                <p>
                Amennyiben erre kifejezetten nem kérjük vagy fel nem szólítjuk, ne küldjön nekünk és ne is hozzon tudomásunkra semmilyen különleges&nbsp;személyes adatot (pl. társadalombiztosítási számok, a faji vagy etnikai származással, politikai véleménnyel, vallási vagy egyéb meggyőződéssel, egészségi vagy orvosi állapottal, szexuális élettel vagy szexuális irányultsággal, bűnügyi háttérrel vagy szakszervezeti tagsággal kapcsolatos információt, illetve biometrikus vagy genetikai adatokat) a Szolgáltatás felületén vagy rajta keresztül, illetve egyéb módon.
                </p>
                <h2>AUTOMATIKUS INFORMÁCIÓGYŰJTÉS ÉS -FELHASZNÁLÁS</h2>
                <p>
                Mi és szolgáltatóink bizonyos információkat automatikusan begyűjthetünk az Ön Szolgáltatáson belüli barangolása&nbsp;során. Olvassa el a 
                <a href="https://www.visine.hu/cookie-szabalyzat/"> sütikre vonatkozó irányelvet </a>
                 a sütikkel és a Szolgáltatásban használt más nyomon követési technológiákkal kapcsolatos részletes tájékozódás érdekében. A sütiket kizárólag az Ön beleegyezését követően helyezzük el, melyet a süti banneren vagy a preferencia központon keresztül tehet meg. A sütikre vonatkozó irányelv az ezen technológiák letiltásával kapcsolatos információkat is tartalmaz.
                </p>
                <p>
                Mi és szolgáltatóink az alábbi módokon is végezhetnek automatikus információgyűjtést és -feldolgozást:
                </p>
                <ul>
                  <li>
                    <span className="highlighted">
                      Az Ön böngészőjén keresztül:
                    </span>{" "}
                  Bizonyos adatok a legtöbb böngésző segítségével begyűjthetők, mint például az Ön eszközhozzáférés-vezérlési (MAC) címe, a számítógép típusa (Windows vagy Mac), a képernyőfelbontás, az operációs rendszer verziója, valamint az internetböngésző típusa és verziója. Hasonló információkat, mint például az Ön eszközének típusát és azonosítóját is begyűjthetjük, ha Ön mobileszköz segítségével fér hozzá a Szolgáltatáshoz. Ezeket az információkat a Szolgáltatás megfelelő működésének biztosításához használjuk fel.
                  </li>
                  <li>
                    <span className="highlighted">IP-cím:</span> Az IP-cím egy számsor, amelyet az Ön internetszolgáltatója automatikusan hozzárendel a számítógépéhez. Szerverünk az IP-címeket, a látogatás időpontját és a megtekintett oldal(aka)t automatikusan azonosítja és naplózza, amikor egy felhasználó ellátogat a Szolgáltatás felületére. Az IP-címek gyűjtése egy általános gyakorlat, amelyet számos online szolgáltatás automatikusan végez. Az IP-címeket többek között a Szolgáltatás látogatottságának meghatározásához, kiszolgálóproblémák diagnosztizálásához és a Szolgáltatás adminisztrációjához használjuk fel. IP-címéből hozzávetőleges tartózkodási helyét is megállapíthatjuk.
                  </li>
                  <li>
                    <span className="highlighted">Eszközinformáció:</span>{" "}
                    Mobileszközéről is gyűjthetünk információkat, például az
                    egyéni eszközazonosítót, annak megismerése érdekében, hogy
                    miként használja a Szolgáltatást.
                  </li>
                </ul>
                <h2>HOGYAN HASZNÁLJUK ÉS TESSZÜK KÖZZÉ AZ INFORMÁCIÓT</h2>
                <p>
                Az Ön által megadott információkat a gyűjtés helyén ismertetett módon használjuk fel. Az általunk történő bizonyos felhasználások és közzétételek letiltási módjának megismeréséhez tekintse át a „Választási lehetőségek és hozzáférés” című szakaszt.
                </p>
                <p>
                Ahol az alkalmazandó jogszabályok megkövetelik, az információgyűjtési ponton kérni fogjuk, hogy egyezzen bele a személyes adatainak általunk történő felhasználásába.&nbsp; Az Öntől kapott vagy Önre vonatkozó információkat szükség szerint szerződések teljesítéséhez, a jogi (pl. farmakovigilanciai) kötelezettségeinknek való elégtételhez vagy jogos üzleti érdekeink szolgálatában is felhasználhatjuk.&nbsp; Egyéb jogi indokokra is támaszkodhatunk, különösen:
                </p>
                <ul>
                  <li>
                    <span className="highlighted">
                      A Szolgáltatás működésének fenntartása, valamint az Ön
                      kéréseinek teljesítése.
                    </span>
                    <ul>
                      <li>
                      a Szolgáltatás működésének, valamint a kapcsolódó ügyfélszolgálati tevékenység biztosítása az Ön számára;
                      </li>
                      <li>
                      az Ön kérdéseinek megválaszolása és az Ön kéréseinek teljesítése, mint például Ön által kért dokumentumok vagy e-mail értesítések küldése;
                      </li>
                      <li>
                      a közöttünk fennálló kapcsolatra vagy a Szolgáltatásra, a feltételeink és irányelveink változására vonatkozó fontos információk és/vagy egyéb adminisztratív tájékoztatás eljuttatása Önhöz.
                      </li>
                    </ul>
                    <p>
                    Ezen tevékenységeket azzal a céllal végezzük, hogy kezeljük az Önnel fennálló szerződéses kapcsolatunkat és/vagy eleget tegyünk valamely jogi kötelezettségnek.
                    </p>
                  </li>
                  <li>
                    <span className="highlighted">
                      Üzleti céljaink elérése.
                    </span>
                    <ul>
                      <li>
                        adatelemzés céljára, például, hogy javítsuk a
                        Szolgáltatás hatékonyságát;
                      </li>
                      <li>
                        auditok céljára, hogy igazoljuk, belső folyamataink a
                        kívánt módon működnek, valamint megfelelnek a jogi,
                        szabályozási, illetve szerződéses követelményeknek;
                      </li>
                      <li>
                        csalás és biztonsági ellenőrzés céljára, például,
                        kibertámadások vagy személyazonosság-lopás kísérletének
                        felfedése vagy megakadályozása érdekében;
                      </li>
                      <li>
                        új termékek és szolgáltatások fejlesztése céljára;
                      </li>
                      <li>
                        weboldalunk, illetve termékeink és szolgáltatásaink
                        kiterjesztése, javítása vagy módosítása céljára;
                      </li>
                      <li>
                      a Szolgáltatás igénybevételi tendenciák meghatározása céljára, például, annak megismerése érdekében, hogy Szolgáltatásunk mely részei iránt a legnagyobb az érdeklődés a felhasználók részéről; továbbá
                      </li>
                      <li>
                      promóciós kampányaink hatékonyságának meghatározása céljára, hogy a felhasználók szükségleteihez és érdeklődési köréhez illeszthessük kampányainkat.
                      </li>
                    </ul>
                    <p>
                    Ezen tevékenységeket azzal a céllal végezzük, hogy kezeljük az Önnel fennálló szerződéses kapcsolatunkat, eleget tegyünk valamely jogi kötelezettségnek, és/vagy mert jogos érdekeink ezt kívánják.
                    </p>
                  </li>
                  <li>
                    <span className="highlighted">
                      Személyes adatok elemzése személyre szabott szolgáltatás
                      biztosítása érdekében.
                    </span>
                    <ul>
                      <li>
                      hogy jobban megismerhessük Önt, így személyre szabhatjuk az Önnel való együttműködésünket, és az Ön érdeklődési körének megfelelő információkat és/vagy ajánlatokat juttathatjuk el Önhöz;
                      </li>
                      <li>
                      hogy jobban megismerhessük preferenciáit, így a Szolgáltatáson keresztül olyan tartalmakat közvetíthessünk, amely megítélésünk szerint, releváns és érdekes lehet az Ön számára.
                      </li>
                    </ul>
                    <p>
                    Személyre szabott szolgáltatásokat nyújtunk vagy az Ön beleegyezése alapján, vagy mert ez szolgálja jogos érdekeinket.
                    </p>
                  </li>
                </ul>
                <p>
                  Ezenkívül a Szolgáltatáson keresztül gyűjtött adatokat átadjuk
                  továbbá:
                </p>
                <ul>
                  <li>
                  társvállalatainknak a jelen&nbsp;Adatvédelmi irányelvben ismertetett célokra. Társvállalataink felsorolása megtekinthető a{""} 
                  <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">https://investors.kenvue.com/financials-reports/sec-filings/default.aspx</a>
                   &nbsp;- hozzáférhet a Kenvue későbbi beadványaihoz az Értékpapír-tőzsdefelügyelethez. A(z) JNTL Consumer Health (Hungary) Kft. a közösen felhasznált személyes adatok kezeléséért felelős fél;
                  </li>
                  <li>
                  külső partnereinknek, akikkel együtt közös márka- vagy értékesítési akciókat kínálunk fel;
                  </li>
                  <li>
                  külső szolgáltató partnereinknek, amelyek olyan szolgáltatásokat nyújtanak, mint webhely üzemeltetés és -moderálás, mobilalkalmazások üzemeltetése, adatelemzés, kifizetések feldolgozása, rendelések teljesítése, infrastruktúra szolgáltatás, informatikai szolgáltatások, ügyfélszolgálat, e-mail- és DM-küldő szolgáltatások, auditálási szolgáltatások és egyéb szolgáltatások, amelyek segítségével lehetővé válik számukra a szolgáltatások nyújtása; valamint
                  </li>
                  <li>
                  az alkalmazandó jogszabályok által megengedettek szerint harmadik fél számára, a következők esetén: bármely átszervezés, összeolvadás, értékesítés, közös vállalatba vonás, átruházás, engedményezés, illetve vállalatunk, eszközeink vagy árukészletünk teljes egésze vagy bármely része fölötti egyéb diszponálás (beleértve az esetleges csőddel vagy hasonló eljárással kapcsolatos eseteket is).
                  </li>
                </ul>
                <p>
                Ezenkívül az információit felhasználhatjuk és közzétehetjük az alábbiakhoz szükségesnek vagy helyénvalónak ítéltek szerint: (a) a jogi eljárásoknak vagy alkalmazandó jogszabályoknak való megfeleléshez, beleértve az Ön lakóhelye szerinti országon kívül érvényes jogszabályokat is; (b) hogy a jogszabályok által megengedettek szerint reagáljunk a közvélemény és a hatóságok részéről érkező kérésekre, beleértve az Ön lakóhelye szerinti országon kívüli hatóságokat is; (c) hogy érvényt szerezzünk&nbsp;üzleti feltételeinknek; és (d) hogy védhessük saját és/vagy társvállalataink, az Ön vagy mások jogait, személyes adatait, biztonságát vagy tulajdonát. Adatait egyéb módokon is felhasználhatjuk, illetve közzétehetjük, amennyiben ehhez előzetes jóváhagyását kértük.
                </p>
                <p>
                Az automatikusan begyűjtött információkat a fenti „Automatikus információgyűjtés és -felhasználás” szakaszban leírtak szerint használhatjuk fel, illetve tehetjük közzé.
                </p>
                <p>
                Továbbá, ha az alkalmazandó jogszabályok megengedik, azt az információt is felhasználhatjuk és közzétehetjük bármely célból, amely személyazonosításra nem alkalmas. Amennyiben személyes azonosításra nem alkalmas adatokat összekapcsoljuk az érintettel kapcsolatba hozható információkkal (például az Ön nevét összekapcsoljuk a földrajzi helyével), az összekapcsolt információkat mindaddig személyes adatként kezeljük, amíg a kapcsolat közöttük fennáll.
                </p>
                <h2>VÁLASZTÁSI LEHETŐSÉGEK ÉS HOZZÁFÉRÉS</h2>
                <h3>
                Az általunk felhasznált és közzétett személyes adatokkal kapcsolatos választási lehetőségei
                </h3>
                <p>
                Az általunk marketing célra felhasznált és közzétett személyes adataival kapcsolatban választási lehetőségeket biztosítunk. Úgy dönthet, hogy nem kér a következőkből:
                </p>
                <ul>
                  <li>
                    <span className="highlighted">
                      Marketinges kommunikáció fogadása tőlünk:
                    </span>{" "}
                    Ha a továbbiakban nem kíván marketinges kommunikációt kapni tőlünk, akkor ennek érdekében lépjen kapcsolatba velünk a következő módokon:{" "}
                    <a
                      className="highlighted"
                      href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000ByTK"
                      target="_blank"
                    >
                      Kapcsolat
                    </a>
                    . A hozzánk intézett megkeresésben adja meg a nevét, nevezze meg annak a marketinges kommunikációnak a formáját (formáit), amelye(ke)t a továbbiakban nem kíván fogadni, valamint tüntesse fel az(oka)t a címe(ke)t, ahová mindezt küldik. Például, ha a továbbiakban nem kíván tőlünk marketing célú e-maileket vagy DM-küldeményeket kapni, közölje ezt, és adja meg nevét, valamint e-mail címét, illetve postacímét. Ezenkívül úgy is dönthet amellett, hogy nem szeretne marketing célú kommunikációt kapni tőlünk, ha követi az egyes üzenetekben közölt leiratkozási utasításokat.
                  </li>
                  <li>
                    <span className="highlighted">
                      Az általunk küldött emlékeztetők fogadása:
                    </span>{" "}
                    Ha a továbbiakban nem kíván tőlünk egészségügyi emlékeztetőket kapni, dönthet amellett, hogy nem fogadja őket, ha kapcsolatba lép velünk{" "}
                    <a
                      className="highlighted"
                      href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000ByTK"
                      target="_blank"
                    >
                      Kapcsolat
                    </a>
                    . Nekünk szóló válaszában adja meg nevét és azt az e-mail címet vagy telefonszámot, amelyen emlékeztetőinket fogadja.
                  </li>
                  <li>
                    <span className="highlighted">
                      A társvállalatokkal és külső partnerekkel általunk
                      megosztott személyes adatok:
                    </span>{" "}
                    Ha korábban beleegyezett marketing célú kommunikációk fogadásába társvállalataink vagy külső partnereink részéről, bármikor dönthet úgy, hogy nem szeretné, ha a jövőben direkt marketing céljából megosztanánk velük az Ön személyes adatait, ha a lejelentkezéshez kapcsolatba lép velünk{" "}
                    <a
                      className="highlighted"
                      href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000ByTK"
                      target="_blank"
                    >
                      Kapcsolat
                    </a>
                    . A hozzánk intézett megkeresésben jelentse ki, hogy ezentúl a személyes adatait marketing célú felhasználásra nem oszthatjuk meg társvállalatainkkal és/vagy külső partnereinkkel, és szerepeljen benne a neve és az e-mail címe is.
                  </li>
                </ul>
                <p>
                Amint ez ésszerűen kivitelezhető, igyekszünk teljesíteni a kérését (kéréseit). Kérjük, vegye figyelembe, hogy amennyiben a fentiek szerint a lejelentkezésről dönt, nem fogjuk tudni törölni az Ön személyes adatait azon társvállalataink adatbázisából, akikkel az Ön adatait már megosztottuk (vagyis attól a dátumtól kezdődően, amikor Önnek az erre vonatkozó kérését teljesítjük). Ugyanakkor ésszerű erőfeszítéseket teszünk annak érdekében, hogy értesítsük leányvállalatainkat kéréséről. Kérjük, azt is vegye figyelembe, hogy amennyiben úgy dönt, hogy a továbbiakban nem kíván tőlünk marketing célú üzeneteket kapni, még mindig küldhetünk fontos tranzakciókra vonatkozó és adminisztratív tartalmú üzeneteket, amelyekről nem jelentkezhet le.
                </p>
                <h3>
                Hogyan férhet hozzá, változtathatja meg, illetve törölheti saját személyes adatait.
                </h3>
                <p>
                Ha szeretné áttekinteni, kijavítani, aktualizálni, korlátozni vagy törölni személyes adatait, vagy szeretné megkapni személyes adatainak elektronikus másolatát azok valamely más vállalatnak történő továbbításához (amennyiben az alkalmazandó jogszabályok biztosítják az ehhez való jogot), kérjük, lépjen velünk kapcsolatba:{" "}
                  <a
                    className="highlighted"
                    href="mailto:emeaprivacy@kenvue.com"                    
                  >
                    emeaprivacy@kenvue.com
                  </a>
                  . Kérésére a lehető leggyorsabban válaszolunk, legkésőbb egy hónappal a kézhezvételt követően. Ha valamilyen körülmény válaszadásunk késedelmét idézi elő, azonnal értesítjük, és megadjuk a válasz várható dátumát.
                </p>
                <h2>KÜLFÖLDRE TÖRTÉNŐ TOVÁBBÍTÁS</h2>
                <p>
                Az Ön személyes adatait bármely olyan országban tárolhatják és feldolgozhatják, ahol nekünk létesítményeink vagy szolgáltatóink vannak, és azáltal, hogy Ön a Szolgáltatásunkat használja vagy megadja beleegyezését (ott, ahol ezt jogszabály írja elő), az Ön adatai továbbításra kerülhetnek az Ön lakóhelye szerinti országon kívüli más országokba, beleértve az Egyesült Államokat is, ahol esetleg eltérő adatvédelmi szabályok lehetnek érvényben, mint az Ön országában. Mindazonáltal megfelelő szerződéses és egyéb óvintézkedések biztosítják a személyes adatok védelmét akkor, amikor azokat más országban lévő társvállalatainknak vagy külső partnereinknek továbbítjuk.
                </p>
                <p>
                Az Európai Gazdasági Térségen (EGT) kívüli néhány országot az Európai Bizottság az EGT standardjainak megfelelő adatvédelmi szintet biztosító országként ismer el (ezen országok teljes listáját{""}
                  <a
                    className="highlighted"
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_hu"
                    target="_blank"
                  >
                    itt 
                  </a>
                  találja). A személyes információinak EGT-beli országból olyan országba történő továbbítása esetén, amelyet az Európai Bizottság nem ítél megfelelően biztonságosnak, gondoskodunk róla, hogy személyes adatainak védelme érdekében a fogadó felet kösse (kössék) a(z) EU által elfogadott egységes szerződési feltételek, Ha felkeresi az adatvédelmi tisztviselőt az alábbi „Elérhetőségeink” szakaszban foglalt utasítások szerint, szert tehet ezen óvintézkedések másolatára.
                </p>
                <h2>BIZTONSÁG</h2>
                <p>
                Indokolt mértékű szervezeti, műszaki és adminisztrációs lépéseket alkalmazunk az ellenőrzésünk alatt álló személyes adatok védelme érdekében. Sajnos az interneten vagy az adattároló rendszereken történő adatátvitel 100%-os biztonsága nem garantálható. Ha okkal véli úgy, hogy a közöttünk fennálló kapcsolat már nem biztonságos (például, ha úgy gondolja, hogy a nálunk létrehozott bármely fiók biztonsága sérült), kérjük, azonnal értesítsen bennünket azáltal, hogy kapcsolatba lép velünk az alábbi „Elérhetőségeink” c. résznek megfelelően.
                </p>
                <h2>ADATMEGŐRZÉSI IDŐ</h2>
                <p>
                Az Ön személyes adatait a felvázolt célok teljesítéséhez szükséges vagy megengedett&nbsp;ideig őrizzük meg. A megőrzési időszakok megállapításához használt kritériumok többek között: (i) az időtartam, amíg kapcsolatban állunk Önnel és a rendelkezésére bocsátjuk a Szolgáltatást; (ii) vonatkozik-e ránk bármiféle jogi kötelezettség; és (iii) jogi pozíciónk fényében (pl. az alkalmazandó elévülési szabályok, perek vagy szabályozói vizsgálatok vonatkozásában) javasolt-e a megőrzés.
                </p>
                <p>
                Például ha egy termékminőségi panaszhoz kapcsolódóan gyűjtünk személyes adatokat, a termék életciklusának végétől számított 10 évig tároljuk azokat.
                </p>
                <p>
                Felhívjuk a figyelmét, azonban arra, hogy ez nem a megőrzési időszakokra vonatkozó teljes lista. Személyes adatait hosszabb ideig is tárolhatjuk a jelen szakasz első bekezdésében felsorolt kritériumok, főként a (ii) és (iii) pont alapján.
                </p>                
                <h2>HARMADIK FÉL WEBOLDALAI ÉS SZOLGÁLTATÁSAI</h2>
                <p>
                Ez a szolgáltatás harmadik felek webhelyeire mutató linkeket tartalmazhat. Ez az Adatvédelmi irányelv nem szabályozza a harmadik felek adatvédelmi, tájékoztatási vagy gyakorlatát és nem is vagyunk felelősek azért -, ideértve bármely olyan weboldalt vagy online szolgáltatást működtető harmadik felet is (korlátozás nélkül beleértve bármely alkalmazást), amely ezen a Szolgáltatáson keresztül érhető el, vagy amelyre ez a Szolgáltatás hivatkozást tartalmaz. A Szolgáltatáson egy ilyen webhely vagy terület elérhetősége vagy egy rá mutató hivatkozás nem jelenti, hogy azt mi vagy bármely társvállalatunk támogatja is.
                </p>
                <h2>ELÉRHETŐSÉGEINK</h2>
                <p>
                A(z) JNTL Consumer Health (Hungary) Kft. székhelyű 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em a személyes információk jelen Adatvédelmi irányelv hatálya alatt történő gyűjtéséért, felhasználásáért és közzétételéért felelős vállalat.
                </p>
                <p>
                Ha kérdése van ezzel az Adatvédelmi irányelvvel kapcsolatban, forduljon hozzánk az alábbi elérhetőségeken{" "}
                  <a
                    className="highlighted"
                    href="mailto:emeaprivacy@kenvue.com"                    
                  >
                    emeaprivacy@kenvue.com
                  </a>
                  , vagy írjon a következő címre:
                </p>
                <p>1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em</p>
                <p>
                Adott esetben az országában vagy régiójában illetékes adatvédelmi tisztviselőt is megkeresheti az{" "}
                  <a
                    className="highlighted"
                    href="mailto:emeaprivacy@kenvue.com"
                  >
                    emeaprivacy@kenvue.com
                  </a>{" "}
                  címen.
                </p>
                
                <h2>PANASZTÉTEL VALAMELY SZABÁLYOZÓ HATÓSÁGNÁL</h2>
                <p>
                Lehetősége van panaszt tenni az Ön országában vagy régiójában illetékes valamely felügyeleti hatóságnál. Kattintson{""}
                 <a href="https://edpb.europa.eu/about-edpb/board/members_hu">ide </a>
                 ezen hatóságok elérhetőségi adataiért.
                </p>
                <h2>AZ ADATVÉDELMI IRÁNYELV FRISSÍTÉSEI</h2>
                <p>
                Előfordulhat, hogy ezt az Adatvédelmi irányelvet megváltoztatjuk. Ennek az Adatvédelmi irányelvnek a módosításai akkor lépnek hatályba, amikor feltesszük a Szolgáltatás felületére a módosított Adatvédelmi irányelvet. Amennyiben Ön a Szolgáltatást ezen módosítások után használja, akkor elfogadja a módosított Adatvédelmi irányelvet. Javasoljuk, hogy a Szolgáltatás felkeresésekor rendszeresen tekintse át Adatvédelmi irányelvünket. Jelen irányelv utolsó frissítésének dátuma: 02.06.2023.
                </p>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Page;
